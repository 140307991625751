import React, { Suspense, lazy, useEffect } from "react";
import "./styles/App.scss";
import "./styles/Global.scss";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PrimeReactProvider } from "primereact/api";
import { ProgressSpinner } from "primereact/progressspinner";
import PrivateRoutes from "./services/privateRoutes";
import { logoutUser } from "./services/store/authSlice";
import { addLocale } from "primereact/api";
import { api } from "./services/config";
import {
  dayNames,
  dayNamesMin,
  dayNamesShort,
  monthNames,
  monthNamesShort,
} from "./services/utils";
import Logout from "./components/auth/Logout";

// import Generic from "./views/Generic";

const Generic = lazy(() => import("./views/Generic"));
const Auth = lazy(() => import("./views/Auth"));

const App = () => {
  const dispatch = useDispatch();
  const lastLogin = useSelector((state) => state.auth.lastLogin);
  const sessionTimeout = 5 * 60 * 1000; // 5 min en ms

  addLocale("fr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: dayNames,
    dayNamesShort: dayNamesShort,
    dayNamesMin: dayNamesMin,
    monthNames: monthNames,
    monthNamesShort: monthNamesShort,
    today: "Aujourd'hui",
    clear: "Clear",
    dateFormat: "dd/mm/yy",
    weekHeader: "J",
  });

  useEffect(() => {
    // Fonction pour vérifier la validité de la session
    const checkSession = async () => {
      if (lastLogin && Date.now() - lastLogin > sessionTimeout) {
        try {
          const validT = await api.get("/auth/test");
        } catch (error) {
          dispatch(logoutUser());
        }
      }
    };
    // Vérif si session toujours valide
    checkSession();
    // Vérif session toutes les 5 minutes
    const intervalId = setInterval(checkSession, sessionTimeout);
    // Nettoyer a intervalle lors du démontage du composant
    return () => clearInterval(intervalId);
  }, [dispatch, lastLogin, sessionTimeout]);

  return (
    <PrimeReactProvider>
      <Router>
        <Suspense
          fallback={
            <div
              style={{ height: "90%" }}
              className="flex justify-content-center align-items-center"
            >
              <ProgressSpinner />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Auth />} />
            <Route exact path="/auth" element={<Auth />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<PrivateRoutes />}>
              <Route path="home" element={<Generic component="Dashboard" />} />
              <Route
                path="kiosks"
                element={<Generic component="Terminals2" />}
              />
              <Route
                path="kiosk/:id"
                element={<Generic component="TerminalPage2" />}
              />
              <Route
                path="campaigns"
                element={<Generic component="Campaigns2" />}
              />
              <Route
                path="campaign/:id"
                element={<Generic component="AdPage2" />}
              />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </PrimeReactProvider>
  );
};

export default App;
