// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newActionBtnR {
  position: relative;
  top: -52px;
  left: calc(100% - 165px);
  margin-right: 10px;
}

.newActionBtnR2 {
  position: absolute;
  top: 93px;
  right: 22px;
  z-index: 4;
}

.returnBtnLeft {
  position: absolute;
  top: 110px;
  right: 22px;
  z-index: 10;
}

.dashboardContent {
  position: relative;
  /* Other styles for your content */
}

.inputFW {
  width: -webkit-fill-available;
}

.backButton {
  margin-left: -20px !important;
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/Global.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,wBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,kCAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAGA;EACE,6BAAA;EACA,uBAAA;AAAF","sourcesContent":[".newActionBtnR {\n  position: relative;\n  top: -52px;\n  left: calc(100% - 165px);\n  margin-right: 10px;\n}\n\n.newActionBtnR2 {\n  position: absolute;\n  top: 93px;\n  right: 22px;\n  z-index: 4;\n}\n\n.returnBtnLeft {\n  position: absolute;\n  top: 110px;\n  right: 22px;\n  z-index: 10;\n}\n\n.dashboardContent {\n  position: relative;\n  /* Other styles for your content */\n}\n\n.inputFW {\n  width: -webkit-fill-available;\n  // padding: 0 2vw;\n}\n\n.backButton {\n  margin-left: -20px !important;\n  color: black !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
