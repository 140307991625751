import axios from "axios";

export const sanitizeInput = (input) => {
  return input
    .replace(/\\/g, "\\\\")
    .replace(/\$/g, "\\$")
    .replace(/'/g, "\\'")
    .replace(/"/g, '\\"');
};

export const convertDateTimeToDate = (dateTime) => {
  const date = new Date(dateTime);
  const dateString = date.toISOString().split("T")[0];
  return dateString;
};

export function convertDate(dateString) {
  const tmp = new Date(dateString);
  return (
    tmp.getDate() + " " + monthNames3L[tmp.getMonth()] + " " + tmp.getFullYear()
  );
}

// Fonction pour générer une clé de cryptage
// export async function generateKey() {
//   const key = await window.crypto.subtle.generateKey(
//     {
//       name: "AES-GCM",
//       length: 256,
//     },
//     true,
//     ["encrypt", "decrypt"]
//   );
//   return key;
// }

// Fonction pour crypter les données
// export async function encryptData(secretData, key) {
//   const encodedData = new TextEncoder().encode(secretData);
//   const encryptedData = await window.crypto.subtle.encrypt(
//     {
//       name: "AES-GCM",
//       iv: window.crypto.getRandomValues(new Uint8Array(12)),
//     },
//     key,
//     encodedData
//   );
//   return encryptedData;
// }

// Fonction pour décrypter les données
// export async function decryptData(encryptedData, key) {
//   const decryptedData = await window.crypto.subtle.decrypt(
//     {
//       name: "AES-GCM",
//       iv: window.crypto.getRandomValues(new Uint8Array(12)),
//     },
//     key,
//     encryptedData
//   );
//   const decodedData = new TextDecoder().decode(decryptedData);
//   return decodedData;
// }

export const dayNames = [
  "lundi",
  "mardi",
  "mercredi",
  "jeudi",
  "vendredi",
  "samedi",
  "dimanche",
];

export const dayNamesShort = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];

export const dayNamesMin = ["L", "M", "M", "J", "V", "S", "D"];

export const monthNames = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

export const monthNamesShort = [
  "Janv",
  "Fév",
  "Mars",
  "Avr",
  "Mai",
  "Juin",
  "Juil",
  "Août",
  "Sept",
  "Oct",
  "Nov",
  "Déc",
];

export const monthNames3L = [
  "Jan",
  "Fev",
  "Mar",
  "Avr",
  "Mai",
  "Jui",
  "Jul",
  "Aoû",
  "Sep",
  "Oct",
  "Nov",
  "Déc",
];
export const typoOptions = [
  {
    name: "Intérieur",
    code: "indoor",
    mainCat: [
      {
        name: "Ouvert au public",
        code: "ind-pub",
        categories: [
          { cname: "Centre Commcercial", code: "ip-mall" },
          { cname: "Magasin", code: "ip-shop" },
          { cname: "Centre Tourisme", code: "ip-tourism" },
        ],
      },
      {
        name: "Semi-privé",
        code: "ind-sm",
        categories: [
          { cname: "Mini Golf", code: "ism-mingolf" },
          { cname: "Bowling", code: "ism-mingolf" },
          { cname: "Cinéma", code: "ism-cinema" },
          { cname: "Complèxe Sportif", code: "ism-compsport" },
          { cname: "Salle de sport", code: "ism-sallsport" },
          { cname: "Organisme Formation", code: "ism-formation" },
        ],
      },
    ],
  },
  {
    name: "Extérieur",
    code: "outdoor",
    mainCat: [
      {
        name: "Ouvert au public",
        code: "out-pub",
        categories: [
          {
            name: "Couvert",
            code: "out-pub-couv",
            categories: [
              { cname: "Complexe sportif", code: "opuc-parc" },
              { cname: "Complexe sportif", code: "opuc-parc" },
            ],
          },
          {
            name: "Non Couvert",
            code: "out-pub-out",
            categories: [
              { cname: "Parc", code: "opuo-parc" },
              { cname: "Parc", code: "opuo-parc" },
            ],
          },
        ],
      },
      {
        name: "Semi-privé",
        code: "out-priv",
        categories: [
          {
            name: "Couvert",
            code: "out-priv-couv",
            categories: [
              { cname: "Complexe sportif", code: "oprc-sport" },
              { cname: "Complexe sportif", code: "oprc-sport" },
            ],
          },
          {
            name: "Non Couvert",
            code: "out-priv-out",
            categories: [
              { cname: "Complexe sportif", code: "opro-sport" },
              { cname: "Complexe sportif", code: "opro-sport" },
            ],
          },
        ],
      },
    ],
  },
];

export const codeToCNameMap = [
  { code: "ip-mall", cname: "Centre Commercial" },
  { code: "ip-shop", cname: "Magasin" },
  { code: "ip-tourism", cname: "Centre Tourisme" },
  { code: "ism-mingolf", cname: "Mini Golf" }, // Notez qu'il y a une erreur dans votre structure originale, 'ism-mingolf' apparaît deux fois avec des valeurs différentes. Ajustez selon le besoin réel.
  { code: "ism-bowling", cname: "Bowling" }, // Correction assumée pour la duplication de 'ism-mingolf'
  { code: "ism-cinema", cname: "Cinéma" },
  { code: "ism-compsport", cname: "Complèxe Sportif" },
  { code: "ism-sallsport", cname: "Salle de sport" },
  { code: "ism-formation", cname: "Organisme Formation" },
  { code: "opuc-parc", cname: "Complexe sportif" }, // Ajustez si 'Complexe sportif' sous 'Couvert' a un cname différent
  { code: "opuo-parc", cname: "Parc" },
  { code: "oprc-sport", cname: "Complexe sportif" }, // Ajustez si 'Complexe sportif' sous 'Semi-privé Couvert' a un cname différent
  { code: "opro-sport", cname: "Complexe sportif" }, // Ajustez si 'Complexe sportif' sous 'Semi-privé Non Couvert' a un cname différent
];

export function hasChanged(comp1, comp2) {
  return comp1 !== comp2;
}

export function hasTypologyChanges(selectedTypology, place_type) {
  return selectedTypology !== findCNameByCode(place_type);
}

export function findCNameByCode(code) {
  const mapping = codeToCNameMap.find((item) => item.code === code);
  return mapping ? mapping.cname : "Code non trouvé";
}

export const searchAddress = async (query) => {
  if (typeof query !== "string" || !query.trim()) return;

  try {
    const { data } = await axios.get(
      `https://nominatim.openstreetmap.org/search?addressdetails=1&q=${query}&format=json&countrycodes=fr`
    );
    return data;
  } catch (error) {
    console.error("Error while fetching addresses:", error);

    return;
  }
};

export const RandProc = (rowData) => {
  return rowData?.is_active ? Math.floor(Math.random() * 100) : 0;
};
export const RandRev = (rowData) => {
  return rowData?.is_active ? Math.floor(Math.random() * 30) : 0;
};
export const RandNet = (rowData) => {
  return rowData?.is_active ? Math.floor(Math.random() * 100) : 0;
};

export const getColorForProc = (value, rowData) => {
  if (rowData?.is_active === false) return "#dbdbdb";
  if (value < 50) return "#abffab";
  if (value < 75) return "#ffd17c";
  return "#ff8282";
};

export const getColorForRev = (value, rowData) => {
  if (rowData?.is_active === false) return "#dbdbdb";
  if (value < 1) return "#dbdbdb";
  if (value < 50) return "#bee0ff";
  return "#abffab";
};

export const getColorForNet = (value, rowData) => {
  if (rowData?.is_active === false) return "#dbdbdb";
  if (value < 30) return "#ff8282";
  if (value < 60) return "#ffd17c";
  return "#abffab";
};

export function generateFakeDataCampaign(campaignData) {
  let data = [];
  let months = [];
  if (campaignData?.is_valid) {
    let startDate = new Date(campaignData?.start_date);
    let endDate = new Date(campaignData?.end_date);
    let startMonth = startDate.getMonth();
    let endMonth = endDate.getMonth();
    let today = new Date();

    if (today >= startDate) {
      if (startMonth > 1) {
        data.push(Math.floor(0));
      }
      for (let i = startMonth; i <= endMonth; i++) {
        data.push(Math.floor(Math.random() * 100) * campaignData.terminals_count);
      }
      if (endMonth < 12) {
        data.push(Math.floor(0));
      }

      months = monthNames3L.filter(
        (month, index) =>
          (index === startMonth - 1 && startMonth > 1) ||
          (index >= startMonth && index <= endMonth) ||
          (index === endMonth + 1 && endMonth < 12)
      );
    }
  }

  return { data: data, labels: months };
}