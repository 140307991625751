import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../services/store/authSlice";
import { ProgressSpinner } from "primereact/progressspinner";

export default function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logoutUser());
    navigate("/auth", { replace: true });
  }, [dispatch, navigate]);

  return (
    <div className="card flex flex-column justify-content-center z-5 absolute top-0 left-0 w-screen h-screen">
      <ProgressSpinner />
    </div>
  );
}
