// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@400;700&family=Arimo:ital,wght@0,400;0,600;0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  background-color: #f2f6ff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-component {
  font-family: "Poppins", sans-serif !important;
}

h1 {
  font-weight: 400;
}

#root {
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.border-component {
  border: 1px solid #ccc;
}

.p-button-warning {
  background-color: #F59E0B;
  border: 1px solid #F59E0B;
}

.p-button-warning:hover {
  background-color: #D97706;
  border: 1px solid #D97706;
}`, "",{"version":3,"sources":["webpack://./src/styles/App.scss"],"names":[],"mappings":"AAKA;EACE,SAAA;EACA,UAAA;EAEA,6CAAA;EACA,yBAAA;EACA,mCAAA;EACA,kCAAA;AAJF;;AAOA;EACE,6CAAA;AAJF;;AAOA;EACE,gBAAA;AAJF;;AAOA;EACE,aAAA;EACA,YAAA;AAJF;;AAOA;EACE,cAAA;EACA,oBAAA;AAJF;;AAOA;EACE;IACE,4CAAA;EAJF;AACF;AAOA;EACE,cAAA;AALF;;AAQA;EACE;IACE,uBAAA;EALF;EAOA;IACE,yBAAA;EALF;AACF;AAQA;EACE,sBAAA;AANF;;AASA;EACE,yBAAA;EACA,yBAAA;AANF;;AASA;EACE,yBAAA;EACA,yBAAA;AANF","sourcesContent":["@import \"Variables\";\n\n//police\n@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@400;700&family=Arimo:ital,wght@0,400;0,600;0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\nbody {\n  margin: 0;\n  padding: 0;\n  //font-family: \"Quicksand\", sans-serif !important;\n  font-family: \"Poppins\", sans-serif !Important;\n  background-color: #f2f6ff;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.p-component {\n  font-family: \"Poppins\", sans-serif !Important;\n}\n\nh1 {\n  font-weight: 400;\n}\n\n#root {\n  height: 100vh;\n  width: 100vw;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.border-component {\n  border: 1px solid #ccc;\n}\n\n.p-button-warning {\n  background-color: #F59E0B;\n  border: 1px solid #F59E0B;\n}\n\n.p-button-warning:hover {\n  background-color: #D97706;\n  border: 1px solid #D97706;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
